import _, { isNull, isUndefined } from "lodash";
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { ScreenProp } from '../../enum/apiResultProp/ScreenProp'
import { ScreenComponentType } from '../../enum/ScreenComponentType'
import { FormProp } from '../../enum/apiResultProp/FormProp';
import { ButtonsProp } from '../../enum/apiResultProp/ButtonsProp';
import { FormFieldProp } from '../../enum/apiResultProp/FormFieldProp';
import { FormButtonProp } from '../../enum/apiResultProp/FormButtonProp';
import { ScreenComponentProp } from "../../enum/apiResultProp/ScreenComponentProp";
import { TableFormProp } from "../../enum/apiResultProp/TableFormProp";
import { TableFormDataRowProp } from "../../enum/apiResultProp/TableFormDataRowProp";
import { TableFormCellProp } from "../../enum/apiResultProp/TableFormCellProp";
import { FlowProp } from "../../enum/apiResultProp/FlowProp";
import { TreeProp } from "../../enum/apiResultProp/TreeProp";
import { ButtonOptionsFormFieldProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldProp";
import { ButtonOptionsFormProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormProp";
import { ItemGroupProp } from "../../enum/apiResultProp/itemGroup/ItemGroupProp";
import { ButtonOptionsFormFieldGroupProp } from "../../enum/apiResultProp/buttonOptionsForm/ButtonOptionsFormFieldGroupProp";
import { InfoPanelProp } from "../../enum/apiResultProp/InfoPanelProp";
import { ModalFormProp } from "../../enum/apiResultProp/ModalFormProp";
import { FieldType } from "../../enum/FieldType";
import { DataGridProp } from "../../enum/dataGrid/DataGridProp";
import { FilterConfigProp } from "../../enum/dataGrid/filter/FilterConfigProp";
import { FilterConfigColumnProp } from "../../enum/dataGrid/filter/FilterConfigColumnProp";
import { FilterProp } from "../../enum/dataGrid/filter/FilterProp";
import { ValueFieldProp } from "../../enum/dataGrid/filter/ValueFieldProp";
import { ComparatorFieldProp } from "../../enum/dataGrid/filter/ComparatorFieldProp";
import { ColumnFieldProp } from "../../enum/dataGrid/filter/ColumnFieldProp";


export const selectAll = (state, { payload }) => {
    const { selectAllRecordsFromCurrentPage, componentName, currentPageRowIds } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const selectable = componentProps[DataGridProp.Selectable];

        if (selectable) {
            let selectedRowIds = componentProps[DataGridProp.SelectedRowIds];

            if (selectAllRecordsFromCurrentPage) {
                selectedRowIds = [...selectedRowIds, ...currentPageRowIds];
            } else {
                selectedRowIds = selectedRowIds.filter(id => !_.includes(currentPageRowIds, id));
            }

            state = {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [DataGridProp.SelectedRowIds]: selectedRowIds
                    }
                }
            }
        }
    }

    return state;
}

export const selectSingle = (state, { payload }) => {
    const { rowId, componentName, currentlyClicked } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const selectable = componentProps[DataGridProp.Selectable];

        if (selectable) {
            let selectedRowIds = componentProps[DataGridProp.SelectedRowIds];

            if (currentlyClicked) {
                selectedRowIds = selectedRowIds.filter(id => id !== rowId);
            } else {
                selectedRowIds = [...selectedRowIds, rowId];
            }

            state = {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [DataGridProp.SelectedRowIds]: selectedRowIds
                    }
                }
            }
        }
    }

    return state;
}

export const dataGridFilterColumnChange = (state, { payload }) => {
    const { newColumnName, oldColumnName, componentName, id } = payload;

    state = dataGridDeleteNotAppliedFilter(state, { payload: { columnName: oldColumnName, componentName, id } });
    state = dataGridCreateFilter(state, { payload: { forColumn: newColumnName, componentName } }, id)

    return state;
}

export const clearAllNotAppliedEmptyFilters = (state, { payload }) => {
    const { componentName } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const allFiltersByColumn = componentProps[DataGridProp.Filters];
        const appliedFilterIdsByColumn = componentProps[DataGridProp.AppliedFiltersIds];

        _.forEach(allFiltersByColumn, (filterForCurrentColumn, columnName) => {
            const appliedFilterIdsForCurrentColumn = appliedFilterIdsByColumn[columnName];

            _.forEach(filterForCurrentColumn, filter => {
                const id = filter[FilterProp.Id];
                const filterValueField = filter[FilterProp.ValueField];
                const filterValueFieldValue = filterValueField[ValueFieldProp.Value];
                const fitlerIsApplied = _.includes(appliedFilterIdsForCurrentColumn, id);

                if (!fitlerIsApplied && !filterValueFieldValue) {
                    const payload = { columnName, componentName, id };
                    state = dataGridDeleteNotAppliedFilter(state, { payload });
                }
            })
        })
    }

    return state;
}

export const dataGridFilterComparatorChange = (state, { payload }) => {
    const { columnName, componentName, id, value } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const currentFiltersForThisColumn = componentProps[DataGridProp.Filters][columnName];

        let filterCollectionIndex = null;
        _.forEach(currentFiltersForThisColumn, (filterProps, index) => {
            const filterId = filterProps[FilterProp.Id];
            if (id === filterId) {
                filterCollectionIndex = index;
                return;
            }
        })

        currentFiltersForThisColumn[filterCollectionIndex][FilterProp.ComparatorField][ComparatorFieldProp.Value] = value;


        const currentValueFieldType = currentFiltersForThisColumn[filterCollectionIndex][FilterProp.ValueField][ValueFieldProp.FieldType];
        const newValueField = componentProps[DataGridProp.FilterConfig][FilterConfigProp.Columns][columnName][FilterConfigColumnProp.ValueFields][value];
        if (currentValueFieldType !== newValueField[ValueFieldProp.FieldType]) {
            currentFiltersForThisColumn[filterCollectionIndex][FilterProp.ValueField] = { ...newValueField };
        }

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.Filters]: {
                        ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                        [columnName]: currentFiltersForThisColumn
                    }
                }
            }
        }
    }

    return state;
}

export const dataGridFilterValueChange = (state, { payload }) => {
    const { columnName, componentName, id, value } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const currentFiltersForThisColumn = componentProps[DataGridProp.Filters][columnName];

        let filterCollectionIndex = null;
        _.forEach(currentFiltersForThisColumn, (filterProps, index) => {
            const filterId = filterProps[FilterProp.Id];
            if (id === filterId) {
                filterCollectionIndex = index;
                return;
            }
        })

        currentFiltersForThisColumn[filterCollectionIndex][FilterProp.ValueField][ValueFieldProp.Value] = value;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.Filters]: {
                        ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                        [columnName]: currentFiltersForThisColumn
                    }
                }
            }
        }
    }

    return state;
}

export const actionMutateComponent = (state, { payload }) => {
    const componentType = payload[ScreenComponentProp.ScreenComponentType];
    const componentName = payload[ScreenComponentProp.Name];

    const componentDataCurrent = state[ScreenProp.Components][componentName];

    if (componentType === ScreenComponentType.ModalForm) {
        state = mutateModalFormComponent({
            state: state,
            componentDataForUpdate: payload,
            componentDataCurrent: componentDataCurrent,
            componentName: componentName,
        });
    } else if (componentType === ScreenComponentType.DataGrid) {
        state = mutateDataGridComponent({
            state: state,
            componentDataForUpdate: payload,
            componentDataCurrent: componentDataCurrent,
            componentName: componentName,
        });
    }

    return state;
}

export const dataGridDeleteNotAppliedFilter = (state, { payload }) => {
    const { columnName, componentName, id } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const currentFiltersForThisColumn = componentProps[DataGridProp.Filters][columnName];
        const newFiltersForThisColumn = currentFiltersForThisColumn.filter(filter => filter.id !== id);

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.Filters]: {
                        ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                        [columnName]: newFiltersForThisColumn
                    }
                }
            }
        }
    }

    return state;
}

export const dataGridCreateFilter = (state, { payload }, withId = null) => {
    const { forColumn, componentName } = payload;

    const componentProps = state[ScreenProp.Components][componentName];
    const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        const filterConfigProps = componentProps[DataGridProp.FilterConfig];

        const filterConfigsByColumn = filterConfigProps[FilterConfigProp.Columns];
        const columnFieldOptions = filterConfigProps[FilterConfigProp.ColumnFieldOptions];

        let createForColumnName = null;
        if (forColumn) {
            createForColumnName = forColumn;
        } else {
            let isFirst = true;
            _.forEach(filterConfigsByColumn, (filterConfigProps, columnName) => {
                if (isFirst) {
                    createForColumnName = columnName;
                    isFirst = false;
                }
            })
        }

        const filterConfigForCurrentColumn = filterConfigsByColumn[createForColumnName];
        if (filterConfigForCurrentColumn) {
            const comparators = filterConfigForCurrentColumn[FilterConfigColumnProp.Comparators];
            const defaultComparator = filterConfigForCurrentColumn[FilterConfigColumnProp.DefaultComparator];
            const valueFieldsByComparator = filterConfigForCurrentColumn[FilterConfigColumnProp.ValueFields];
            const valueFieldForDefaultComparator = valueFieldsByComparator[defaultComparator];

            // ID Logic
            let newFilterId;
            if (withId !== null) {
                newFilterId = withId;
            } else {
                const lastFilterId = filterConfigProps[FilterConfigProp.LastFilterId];
                const nextFilterId = lastFilterId + 1;
                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [DataGridProp.FilterConfig]: {
                                ...state[ScreenProp.Components][componentName][DataGridProp.FilterConfig],
                                [FilterConfigProp.LastFilterId]: nextFilterId
                            }
                        }
                    }
                }

                newFilterId = nextFilterId;
            }

            const newFilterObject = {
                [FilterProp.Id]: newFilterId,
                [FilterProp.ColumnField]: {
                    options: columnFieldOptions,
                    value: createForColumnName
                },
                [FilterProp.ComparatorField]: {
                    options: comparators,
                    value: defaultComparator
                },
                [FilterProp.ValueField]: { ...valueFieldForDefaultComparator } // If we pass the object here it is passed by reference and becomes a big mess
            };

            // Add Filter
            const currentFiltersForThisColumn = componentProps[DataGridProp.Filters][createForColumnName];
            state = {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [DataGridProp.Filters]: {
                            ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                            [createForColumnName]: [...currentFiltersForThisColumn, newFilterObject]
                        }
                    }
                }
            }
        }
    }

    return state;
}

export const dataGridMutateSpinner = (state, { payload }, spinnerIsActive) => {
    const { dataGridComponentName } = payload;
    const dataGridComponentProps = state[ScreenProp.Components][dataGridComponentName];
    const componentType = dataGridComponentProps[ScreenComponentProp.ScreenComponentType];

    if (componentType === ScreenComponentType.DataGrid) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [dataGridComponentName]: {
                    ...state[ScreenProp.Components][dataGridComponentName],
                    [DataGridProp.LoaderIsActive]: spinnerIsActive
                }
            }
        }
    } else {
        console.log('dataGridMutateSpinner mutator can be called inly with dataGrid components, but you called it with ' + ScreenComponentType.DataGrid);
    }

    return state;
}

export const fetchScreenData = (state, { payload }) => {
    try {
        const components = payload[ScreenProp.Components];
        const showSpinner = payload[ScreenProp.ShowSpinner];
        const hasInputFields = payload[ScreenProp.HasInputFields];

        //TODO Find Fields with type RichTextEditor and change the value
        _.forEach(components, (component, componentName) => {
            const componentType = component[ScreenComponentProp.ScreenComponentType];
            if (componentType === ScreenComponentType.Form) {
                const rows = component[FormProp.Fields];
                _.forEach(rows, (fields, rowKey) => {
                    _.forEach(fields, (field, fieldName) => {
                        const fieldType = field[FormFieldProp.FieldType];
                        if (fieldType === FieldType.RichTextEditor) {
                            const richTextEditorHtml = field[FormFieldProp.Value];

                            const contentBlock = htmlToDraft(richTextEditorHtml);
                            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                            const editorState = EditorState.createWithContent(contentState);

                            components[componentName][FormProp.Fields][rowKey][fieldName][FormFieldProp.Value] = editorState;
                        }
                    })
                });
            }
        })

        return {
            ...state,
            [ScreenProp.Components]: components,
            [ScreenProp.ShowSpinner]: showSpinner,
            [ScreenProp.HasInputFields]: hasInputFields,
        };
    } catch (ex) {
        console.log('Something went wrong on data fetch! Exception: ' + ex);
        return state;
    }
}

export const formFieldValueChange = (state, { payload }) => {
    try {
        const { componentName, rowKey, fieldName, propsChanged, componentType } = payload;

        if (componentType === ScreenComponentType.Form || componentType === ScreenComponentType.ModalForm) {
            _.forEach(propsChanged, (propValue, propName) => {
                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [FormProp.Fields]: {
                                ...state[ScreenProp.Components][componentName][FormProp.Fields],
                                [rowKey]: {
                                    ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey],
                                    [fieldName]: {
                                        ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey][fieldName],
                                        [propName]: propValue
                                    }
                                }
                            }
                        }
                    },
                }
            })

            return state;
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const formFieldErrorChange = (state, { payload }) => {
    try {
        const { componentName, rowKey, fieldName, hasError, errorMessage, componentType } = payload;

        if (componentType === ScreenComponentType.Form || componentType === ScreenComponentType.ModalForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [FormProp.Fields]: {
                            ...state[ScreenProp.Components][componentName][FormProp.Fields],
                            [rowKey]: {
                                ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey],
                                [fieldName]: {
                                    ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey][fieldName],
                                    [FormFieldProp.HasError]: hasError,
                                    [FormFieldProp.ErrorMessage]: errorMessage,
                                }
                            }
                        }
                    }
                },
            };
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const actionMutateScreen = (state, { payload }) => {
    const componentsDataForUpdate = payload[ScreenProp.Components];
    const currentlyExistingScreenComponents = state[ScreenProp.Components];

    _.forEach(currentlyExistingScreenComponents, (componentProps, componentName) => {
        const componentDataForUpdate = componentsDataForUpdate[componentName];

        const componentShouldDisappear = _.isUndefined(componentDataForUpdate);
        if (componentShouldDisappear) {
            state = {
                ...state,
                [ScreenProp.Components]: _.omit(state[ScreenProp.Components], componentName)
            }
        } else {
            const componentType = componentProps[ScreenComponentProp.ScreenComponentType];
            if (componentType === ScreenComponentType.InfoPanel) {
                _.forEach(componentDataForUpdate, (propValue, propName) => {
                    if (propName === InfoPanelProp.ContentRows) {
                        _.forEach(propValue, (contentRowProps, contentRowKey) => {

                            _.forEach(contentRowProps, (contentRowPropValue, contentRowPropName) => {
                                state = {
                                    ...state,
                                    [ScreenProp.Components]: {
                                        ...state[ScreenProp.Components],
                                        [componentName]: {
                                            ...state[ScreenProp.Components][componentName],
                                            [InfoPanelProp.ContentRows]: {
                                                ...state[ScreenProp.Components][componentName][InfoPanelProp.ContentRows],
                                                [contentRowKey]: {
                                                    ...state[ScreenProp.Components][componentName][InfoPanelProp.ContentRows][contentRowKey],
                                                    [contentRowPropName]: contentRowPropValue
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        })
                    } else {
                        state = {
                            ...state,
                            [ScreenProp.Components]: {
                                ...state[ScreenProp.Components],
                                [componentName]: {
                                    ...state[ScreenProp.Components][componentName],
                                    [propName]: propValue,
                                }
                            }
                        }
                    }
                });
            } else if (componentType === ScreenComponentType.TreeWithSidePanel) {
                const fieldGroups = componentDataForUpdate[ButtonOptionsFormProp.FieldGroups];
                const title = componentDataForUpdate[ButtonOptionsFormProp.Title];
                const items = componentDataForUpdate[ItemGroupProp.Items];
                const separatedItems = componentDataForUpdate[ItemGroupProp.SeparatedItems];

                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [ButtonOptionsFormProp.FieldGroups]: fieldGroups,
                            [ButtonOptionsFormProp.Title]: title,
                            [ItemGroupProp.Items]: items,
                            [ItemGroupProp.SeparatedItems]: separatedItems,
                        }
                    }
                }
            } else if (componentType === ScreenComponentType.Form) {
                const fieldRowsForUpdate = componentDataForUpdate[FormProp.Fields];

                const fieldRows = componentProps[FormProp.Fields];

                //Logic for update existing fields
                if (fieldRows && fieldRowsForUpdate) {
                    _.forEach(fieldRows, (rowFields, rowKey) => {
                        const rowFieldsForUpdate = fieldRowsForUpdate[rowKey];
                        if (_.isUndefined(rowFieldsForUpdate)) {
                            return;
                        }

                        _.forEach(rowFields, (fieldProps, fieldName) => {
                            const fieldPropsForUpdate = rowFieldsForUpdate[fieldName];
                            if (_.isUndefined(fieldPropsForUpdate)) {
                                return;
                            }

                            _.forEach(fieldProps, (propValue, propName) => {
                                const propValueForUpdate = fieldPropsForUpdate[propName];
                                if (_.isUndefined(propValueForUpdate)) {
                                    return;
                                }

                                if (propValue == propValueForUpdate) {
                                    return;
                                }

                                state = {
                                    ...state,
                                    [ScreenProp.Components]: {
                                        ...state[ScreenProp.Components],
                                        [componentName]: {
                                            ...state[ScreenProp.Components][componentName],
                                            [FormProp.Fields]: {
                                                ...state[ScreenProp.Components][componentName][FormProp.Fields],
                                                [rowKey]: {
                                                    ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey],
                                                    [fieldName]: {
                                                        ...state[ScreenProp.Components][componentName][FormProp.Fields][rowKey][fieldName],
                                                        [propName]: propValueForUpdate
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    });
                }
            } else if (componentType === ScreenComponentType.Buttons) {
                const buttonsForUpdate = componentDataForUpdate[ButtonsProp.Buttons];
                const buttons = componentProps[ButtonsProp.Buttons];

                //Logic for update existing buttons
                if (buttons && buttonsForUpdate) {
                    _.forEach(buttons, (buttonProps, buttonName) => {
                        const buttonPropsForUpdate = buttonsForUpdate[buttonName];
                        if (_.isUndefined(buttonPropsForUpdate)) {
                            return;
                        }

                        _.forEach(buttonProps, (buttonPropValue, buttonPropName) => {
                            const buttonPropValueForUpdate = buttonPropsForUpdate[buttonPropName];
                            if (_.isUndefined(buttonPropValueForUpdate)) {
                                return;
                            }

                            if (buttonPropValue == buttonPropValueForUpdate) {
                                return;
                            }

                            state = {
                                ...state,
                                [ScreenProp.Components]: {
                                    ...state[ScreenProp.Components],
                                    [componentName]: {
                                        ...state[ScreenProp.Components][componentName],
                                        [ButtonsProp.Buttons]: {
                                            ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons],
                                            [buttonName]: {
                                                ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons][buttonName],
                                                [buttonPropName]: buttonPropValueForUpdate
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            } else if (componentType === ScreenComponentType.TableForm) {
                const tableFormDataRowsChanges = componentDataForUpdate[TableFormProp.TableFormDataRows];
                const tableFormDataRowsForHide = componentDataForUpdate[TableFormProp.TableFormDataRowsForDelete];
                const tableFormDataRowsCurrent = state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows];
                const allExistingUniqueRowIds = _.keys(tableFormDataRowsCurrent);

                //Add new Rows, Mutate existing rows
                _.forEach(tableFormDataRowsChanges, (row, uniqueRowId) => {
                    let rowShouldBeAddedToState = false;
                    let rowShouldBeMutatedInState = false;
                    if (_.includes(allExistingUniqueRowIds, uniqueRowId)) {
                        rowShouldBeMutatedInState = true;
                    } else {
                        rowShouldBeAddedToState = true;
                    }

                    if (rowShouldBeAddedToState) {
                        state = {
                            ...state,
                            [ScreenProp.Components]: {
                                ...state[ScreenProp.Components],
                                [componentName]: {
                                    ...state[ScreenProp.Components][componentName],
                                    [TableFormProp.TableFormDataRows]: {
                                        ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows],
                                        [uniqueRowId]: row
                                    }
                                }
                            }
                        }
                    } else if (rowShouldBeMutatedInState) {
                        //If problem occures here -> make the state update of thr row property by property; for now is a little bit shallow
                        state = {
                            ...state,
                            [ScreenProp.Components]: {
                                ...state[ScreenProp.Components],
                                [componentName]: {
                                    ...state[ScreenProp.Components][componentName],
                                    [TableFormProp.TableFormDataRows]: {
                                        ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows],
                                        [uniqueRowId]: row
                                    }
                                }
                            }
                        }
                    }
                })

                //Hide Rows
                _.forEach(tableFormDataRowsForHide, uniqueRowId => {
                    state = {
                        ...state,
                        [ScreenProp.Components]: {
                            ...state[ScreenProp.Components],
                            [componentName]: {
                                ...state[ScreenProp.Components][componentName],
                                [TableFormProp.TableFormDataRows]: _.omit(state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows], uniqueRowId)
                            }
                        }
                    }
                });
            } else if (componentType === ScreenComponentType.Flow) {
                return;

                const allStepRows = state[ScreenProp.Components][componentName][FlowProp.StepRows];
                _.forEach(allStepRows, (steps, stepRowKey) => {
                    _.forEach(steps, (step, stepKey) => {

                        const currentStepForUpdate = componentDataForUpdate[ScreenProp.Components][componentName][FlowProp.StepRows][stepRowKey][stepKey];

                        state = {
                            ...state,
                            [ScreenProp.Components]: {
                                ...state[ScreenProp.Components],
                                [componentName]: {
                                    ...state[ScreenProp.Components][componentName],
                                    [FlowProp.StepRows]: {
                                        ...state[ScreenProp.Components][componentName][FlowProp.StepRows],
                                        [stepRowKey]: {
                                            ...state[ScreenProp.Components][componentName][FlowProp.StepRows][stepRowKey],
                                            [stepKey]: {
                                                ...state[ScreenProp.Components][componentName][FlowProp.StepRows][stepRowKey][stepKey],
                                                currentStepForUpdate
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    })
                });
            } else if (componentType === ScreenComponentType.ModalForm) {
                state = mutateModalFormComponent({
                    state: state,
                    componentDataForUpdate: componentDataForUpdate,
                    componentDataCurrent: componentProps,
                    componentName: componentName,
                });
            } else if (componentType === ScreenComponentType.DataGrid) {
                state = mutateDataGridComponent({
                    state: state,
                    componentDataForUpdate: componentDataForUpdate,
                    componentDataCurrent: componentProps,
                    componentName: componentName,
                });
            }
        }
    });

    //Component is new - we should show it
    _.forEach(componentsDataForUpdate, (componentProps, componentName) => {
        const componentIsNewlyAdded = _.isUndefined(currentlyExistingScreenComponents[componentName]);

        if (componentIsNewlyAdded) {
            state = {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: componentProps
                }
            }
        }
    });

    return state;
}

export const actionReloadScreen = (state, { payload }) => {
    const newComponents = payload[ScreenProp.Components];

    //TODO maybe I should make more deep mutations, based on the component type
    state[ScreenProp.Components] = {};
    _.forEach(newComponents, (componentProps, componentName) => {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: componentProps
            }
        }
    });

    return state;
}

export const startSpinner = (state) => {
    return {
        ...state,
        [ScreenProp.ShowSpinner]: true,
    };
}

export const stopSpinner = (state) => {
    return {
        ...state,
        [ScreenProp.ShowSpinner]: true,
    };
}

export const handleButtonSpinner = (state, { payload }, showSpinner) => {
    try {
        const { buttonName, componentName, componentType } = payload;

        if (componentType === ScreenComponentType.Buttons) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [ButtonsProp.Buttons]: {
                            ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons][buttonName],
                                [FormButtonProp.ShowSpinner]: showSpinner,
                            }
                        }
                    }
                },
            };
        } else if (componentType === ScreenComponentType.TableForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [TableFormProp.TableFormButtons]: {
                            ...state[ScreenProp.Components][componentName][TableFormProp.TableFormButtons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][TableFormProp.TableFormButtons][buttonName],
                                [FormButtonProp.ShowSpinner]: showSpinner,
                            }
                        }
                    }
                }
            }
        } else if (componentType === ScreenComponentType.ModalForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [ModalFormProp.Buttons]: {
                            ...state[ScreenProp.Components][componentName][ModalFormProp.Buttons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][ModalFormProp.Buttons][buttonName],
                                [FormButtonProp.ShowSpinner]: showSpinner,
                            }
                        }
                    }
                }
            }
        } else if (componentType === ScreenComponentType.DataGrid) {
            // console.log(buttonName, state[ScreenProp.Components][componentName][DataGridProp.Buttons]);
            // return state;
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [DataGridProp.Buttons]: {
                            ...state[ScreenProp.Components][componentName][DataGridProp.Buttons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][DataGridProp.Buttons][buttonName],
                                [FormButtonProp.ShowSpinner]: showSpinner,
                            }
                        }
                    }
                }
            }
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const handleButtonDisable = (state, { payload }, isDisabled) => {
    try {
        const { buttonName, componentName, componentType } = payload;

        if (componentType === ScreenComponentType.Buttons) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [ButtonsProp.Buttons]: {
                            ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons][buttonName],
                                [FormButtonProp.Disabled]: isDisabled,
                            }
                        }
                    }
                },
            };
        } else if (componentType === ScreenComponentType.TableForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [TableFormProp.TableFormButtons]: {
                            ...state[ScreenProp.Components][componentName][TableFormProp.TableFormButtons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][TableFormProp.TableFormButtons][buttonName],
                                [FormButtonProp.Disabled]: isDisabled,
                            }
                        }
                    }
                }
            }
        } else if (componentType === ScreenComponentType.ModalForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [ModalFormProp.TableFormButtons]: {
                            ...state[ScreenProp.Components][componentName][ModalFormProp.TableFormButtons],
                            [buttonName]: {
                                ...state[ScreenProp.Components][componentName][ModalFormProp.TableFormButtons][buttonName],
                                [FormButtonProp.Disabled]: isDisabled,
                            }
                        }
                    }
                }
            }
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const infoPanelHide = (state, { payload }) => {
    try {
        const { componentName } = payload;

        return {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [InfoPanelProp.Hidden]: true
                }
            },
        };
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const modalFormOpen = (state, { payload }) => {
    try {
        const { modalFormComponentName } = payload;

        return {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [modalFormComponentName]: {
                    ...state[ScreenProp.Components][modalFormComponentName],
                    [ModalFormProp.IsOpen]: true
                }
            },
        };
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const handleAllComponentButtonsActiveness = (state, { payload }, isDisabled, showSpinner) => {
    try {
        const { componentName, componentType, skipButtonNames } = payload;

        if (componentType === ScreenComponentType.Buttons) {
            _.forEach(state[ScreenProp.Components][componentName][ButtonsProp.Buttons], (buttonProps, buttonName) => {
                if (!_.includes(skipButtonNames, buttonName)) {
                    state = {
                        ...state,
                        [ScreenProp.Components]: {
                            ...state[ScreenProp.Components],
                            [componentName]: {
                                ...state[ScreenProp.Components][componentName],
                                [ButtonsProp.Buttons]: {
                                    ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons],
                                    [buttonName]: {
                                        ...state[ScreenProp.Components][componentName][ButtonsProp.Buttons][buttonName],
                                        [FormButtonProp.Disabled]: isDisabled,
                                        [FormButtonProp.ShowSpinner]: showSpinner,
                                    }
                                }
                            }
                        },
                    };
                }
            });

            return state;
        } else if (componentType === ScreenComponentType.TableForm) {
            console.log(2313213);


            // const allTableFormRows = state[ScreenProp.Components][componentName]

            // _.forEach()
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

//Table Form Value
export const tableFormFieldValueChange = (state, { payload }) => {
    try {
        const { componentName, rowKey, cellKey, propsChanged, componentType } = payload;

        if (componentType === ScreenComponentType.TableForm) {
            _.forEach(propsChanged, (propValue, propName) => {
                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [TableFormProp.TableFormDataRows]: {
                                ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows],
                                [rowKey]: {
                                    ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey],
                                    [TableFormDataRowProp.Cells]: {
                                        ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells],
                                        [cellKey]: {
                                            ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells][cellKey],
                                            [TableFormCellProp.FieldProps]: {
                                                ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells][cellKey][TableFormCellProp.FieldProps],
                                                [propName]: propValue
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                }
            })

            return state;
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

//Table Form Error
export const tableFormFieldErrorChange = (state, { payload }) => {
    try {
        const { componentName, rowKey, cellKey, hasError, errorMessage, componentType } = payload;

        if (componentType === ScreenComponentType.TableForm) {
            return {
                ...state,
                [ScreenProp.Components]: {
                    ...state[ScreenProp.Components],
                    [componentName]: {
                        ...state[ScreenProp.Components][componentName],
                        [TableFormProp.TableFormDataRows]: {
                            ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows],
                            [rowKey]: {
                                ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey],
                                [TableFormDataRowProp.Cells]: {
                                    ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells],
                                    [cellKey]: {
                                        ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells][cellKey],
                                        [TableFormCellProp.FieldProps]: {
                                            ...state[ScreenProp.Components][componentName][TableFormProp.TableFormDataRows][rowKey][TableFormDataRowProp.Cells][cellKey][TableFormCellProp.FieldProps],
                                            [FormFieldProp.HasError]: hasError,
                                            [FormFieldProp.ErrorMessage]: errorMessage,
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
            };
        } else {
            console.log('Not Registered screenComponent with form inside it');
        }
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
    }

    return state;
}

export const treeExpandElement = (state, { payload }) => {
    try {
        const { componentName, treeElementUniqueId } = payload;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [TreeProp.ExpandedElements]: [
                        ...state[ScreenProp.Components][componentName][TreeProp.ExpandedElements],
                        treeElementUniqueId
                    ]
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const treeCollpaseElement = (state, { payload }) => {
    try {
        const { componentName, treeElementUniqueId } = payload;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [TreeProp.ExpandedElements]: state[ScreenProp.Components][componentName][TreeProp.ExpandedElements].filter(element => element !== treeElementUniqueId)
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const treeExpandAllElements = (state, { payload }) => {
    try {
        const { componentName } = payload;

        const allElementKeys = _.keys(state[ScreenProp.Components][componentName][TreeProp.ElementsAddressBook]);

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [TreeProp.ExpandedElements]: allElementKeys
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const treeCollpaseAllElements = (state, { payload }) => {
    try {
        const { componentName } = payload;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [TreeProp.ExpandedElements]: []
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const treeSelectElement = (state, { payload }) => {
    try {
        const { componentName, treeElementUniqueId } = payload;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [TreeProp.SelectedElementUniqueId]: treeElementUniqueId
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

export const buttonFormChangeValue = (state, { payload }) => {
    try {
        const { componentName, fieldGroupKey, fieldKey, value } = payload;

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [ButtonOptionsFormProp.FieldGroups]: {
                        ...state[ScreenProp.Components][componentName][ButtonOptionsFormProp.FieldGroups],
                        [fieldGroupKey]: {
                            ...state[ScreenProp.Components][componentName][ButtonOptionsFormProp.FieldGroups][fieldGroupKey],
                            [ButtonOptionsFormFieldGroupProp.ButtonOptionFields]: {
                                ...state[ScreenProp.Components][componentName][ButtonOptionsFormProp.FieldGroups][fieldGroupKey][ButtonOptionsFormFieldGroupProp.ButtonOptionFields],
                                [fieldKey]: {
                                    ...state[ScreenProp.Components][componentName][ButtonOptionsFormProp.FieldGroups][fieldGroupKey][ButtonOptionsFormFieldGroupProp.ButtonOptionFields][fieldKey],
                                    [ButtonOptionsFormFieldProp.Value]: value
                                }
                            }
                        }
                    }
                }
            }
        }

        return state;
    } catch (ex) {
        console.log('Something went wrong on form field value change! Exception: ' + ex);
        return state;
    }
}

const mutateDataGridComponent = ({ state, componentDataForUpdate, componentDataCurrent, componentName }) => {
    const newDataRows = componentDataForUpdate[DataGridProp.Data];
    const newSubtotalRow = componentDataForUpdate[DataGridProp.Subtotal];
    const newAppliedFiltersIds = componentDataForUpdate[DataGridProp.AppliedFiltersIds];
    const newPagination = componentDataForUpdate[DataGridProp.Pagination];
    const newSorting = componentDataForUpdate[DataGridProp.Sorting];
    const deleteAllFilters = componentDataForUpdate.deleteAllFilters;
    const deleteAllFiltersForColumnName = componentDataForUpdate.deleteAllFiltersForColumnName;
    const filterRowDeletedColumnName = componentDataForUpdate.filterRowDeletedColumnName;
    const filterRowDeletedId = componentDataForUpdate.filterRowDeletedId;

    state = {
        ...state,
        [ScreenProp.Components]: {
            ...state[ScreenProp.Components],
            [componentName]: {
                ...state[ScreenProp.Components][componentName],
                [DataGridProp.Data]: newDataRows,
                [DataGridProp.AppliedFiltersIds]: newAppliedFiltersIds,
                [DataGridProp.Pagination]: newPagination,
                [DataGridProp.Sorting]: newSorting,
            }
        }
    }

    const unselectableRowIds = componentDataForUpdate[DataGridProp.UnselectableRowIds];
    if (!_.isUndefined(unselectableRowIds)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.UnselectableRowIds]: unselectableRowIds,
                }
            }
        }
    }

    const selectedRowIds = componentDataForUpdate[DataGridProp.SelectedRowIds];
    if (!_.isUndefined(selectedRowIds)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.SelectedRowIds]: selectedRowIds,
                }
            }
        }
    }

    if (newSubtotalRow) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.Subtotal]: newSubtotalRow
                }
            }
        }
    }

    // On ALL Filters Delete 
    const currentFiltersByColumn = componentDataCurrent[DataGridProp.Filters];
    if (deleteAllFilters) {
        _.forEach(currentFiltersByColumn, (currentFiltersForThisColumn, columnName) => {
            if (isNull(deleteAllFiltersForColumnName) || deleteAllFiltersForColumnName === columnName) {
                const newAppliedFiltersIdsForThisColumn = newAppliedFiltersIds[columnName];
                const newFiltersForThisColumn = currentFiltersForThisColumn.filter(filter => _.includes(newAppliedFiltersIdsForThisColumn, filter.id));

                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [DataGridProp.Filters]: {
                                ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                                [columnName]: []
                            }
                        }
                    }
                }
            }
        })
    }


    // On Applied Filter Delete 
    if (filterRowDeletedColumnName && filterRowDeletedId) {
        const currentFiltersForThisColumn = currentFiltersByColumn[filterRowDeletedColumnName];
        const newFiltersForThisColumn = currentFiltersForThisColumn.filter(filter => filter.id !== filterRowDeletedId);

        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [DataGridProp.Filters]: {
                        ...state[ScreenProp.Components][componentName][DataGridProp.Filters],
                        [filterRowDeletedColumnName]: newFiltersForThisColumn
                    }
                }
            }
        }
    }

    return state;
}

const mutateModalFormComponent = ({ state, componentDataForUpdate, componentDataCurrent, componentName }) => {
    //Handle Modal IsOpen mutation
    const isOpen = componentDataForUpdate[ModalFormProp.IsOpen];
    if (!_.isUndefined(isOpen)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [ModalFormProp.IsOpen]: isOpen
                }
            }
        }
    }

    //Handle Modal Message IsHidden mutation
    const modalMessageIsHidden = componentDataForUpdate[ModalFormProp.ModalMessageIsHidden];
    if (!_.isUndefined(modalMessageIsHidden)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [ModalFormProp.ModalMessageIsHidden]: modalMessageIsHidden
                }
            }
        }
    }

    //Handle Modal Message Text mutation
    const modalMessageText = componentDataForUpdate[ModalFormProp.ModalMessageText];
    if (!_.isUndefined(modalMessageText)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [ModalFormProp.ModalMessageText]: modalMessageText
                }
            }
        }
    }

    //Handle Modal Message Style mutation
    const modalMessageStyle = componentDataForUpdate[ModalFormProp.ModalMessageStyle];
    if (!_.isUndefined(modalMessageStyle)) {
        state = {
            ...state,
            [ScreenProp.Components]: {
                ...state[ScreenProp.Components],
                [componentName]: {
                    ...state[ScreenProp.Components][componentName],
                    [ModalFormProp.ModalMessageStyle]: modalMessageStyle
                }
            }
        }
    }

    //Handle Modal Form Fields mutation
    const fieldRowsForUpdate = componentDataForUpdate[ModalFormProp.Fields];
    const fieldRows = componentDataCurrent[ModalFormProp.Fields];
    if (fieldRowsForUpdate) {
        _.forEach(fieldRows, (rowFields, rowKey) => {
            const rowFieldsForUpdate = fieldRowsForUpdate[rowKey];
            if (_.isUndefined(rowFieldsForUpdate)) {
                return;
            }

            _.forEach(rowFields, (fieldProps, fieldName) => {
                const fieldPropsForUpdate = rowFieldsForUpdate[fieldName];
                if (_.isUndefined(fieldPropsForUpdate)) {
                    return;
                }

                _.forEach(fieldProps, (propValue, propName) => {
                    const propValueForUpdate = fieldPropsForUpdate[propName];
                    if (_.isUndefined(propValueForUpdate)) {
                        return;
                    }

                    if (propValue == propValueForUpdate) {
                        return;
                    }

                    state = {
                        ...state,
                        [ScreenProp.Components]: {
                            ...state[ScreenProp.Components],
                            [componentName]: {
                                ...state[ScreenProp.Components][componentName],
                                [ModalFormProp.Fields]: {
                                    ...state[ScreenProp.Components][componentName][ModalFormProp.Fields],
                                    [rowKey]: {
                                        ...state[ScreenProp.Components][componentName][ModalFormProp.Fields][rowKey],
                                        [fieldName]: {
                                            ...state[ScreenProp.Components][componentName][ModalFormProp.Fields][rowKey][fieldName],
                                            [propName]: propValueForUpdate
                                        }
                                    }
                                }
                            }
                        }
                    }
                })
            })
        });
    }

    //Handle Modal Form Buttons mutation
    const buttonsForUpdate = componentDataForUpdate[ModalFormProp.Buttons];
    const buttons = componentDataCurrent[ModalFormProp.Buttons];

    if (buttonsForUpdate) {
        _.forEach(buttons, (buttonProps, buttonName) => {
            const buttonPropsForUpdate = buttonsForUpdate[buttonName];
            if (_.isUndefined(buttonPropsForUpdate)) {
                return;
            }

            _.forEach(buttonProps, (buttonPropValue, buttonPropName) => {
                const buttonPropValueForUpdate = buttonPropsForUpdate[buttonPropName];
                if (_.isUndefined(buttonPropValueForUpdate)) {
                    return;
                }

                if (buttonPropValue == buttonPropValueForUpdate) {
                    return;
                }

                state = {
                    ...state,
                    [ScreenProp.Components]: {
                        ...state[ScreenProp.Components],
                        [componentName]: {
                            ...state[ScreenProp.Components][componentName],
                            [ModalFormProp.Buttons]: {
                                ...state[ScreenProp.Components][componentName][ModalFormProp.Buttons],
                                [buttonName]: {
                                    ...state[ScreenProp.Components][componentName][ModalFormProp.Buttons][buttonName],
                                    [buttonPropName]: buttonPropValueForUpdate
                                }
                            }
                        }
                    }
                }
            });
        });
    }

    return state;
}