import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ScreenComponentProp } from '../enum/apiResultProp/ScreenComponentProp';
import { ScreenComponentType } from '../enum/ScreenComponentType';

import Form from './form/form/Form';
import { STRINGS } from '../helpers/strings';
import { getValue } from '../helpers/functions';
import ButtonRow from './form/formButton/row/ButtonRow';
import ScreenTitle from './title/ScreenTitle';
import Table from './table/table/Table';
import TableForm from './tableForm/tableForm/TableForm';
import { ScreenProp } from '../enum/apiResultProp/ScreenProp';
import { Segment, Dimmer, Loader, Divider } from 'semantic-ui-react';
import { getRouteObj } from '../helpers/functions';
import { validateNewScreenComponentsFields } from '../newHelpers/formDataValidation';
import { logout, checkAuth } from '../actions/authActions';
import { addMessage } from '../actions/messageActions';
import fileSaver from '../fileSaver';
import {
    fetchScreenData, formFieldValueChange, formFieldErrorChange, actionDownload, submitForm, clear,
    onFieldChangeMutateScreenWithPostData, onFieldChangeMutateScreenNoPostData,
    onButtonClickMutateScreenWithPostData, onButtonClickMutateScreenNoPostData,
    postDataAndRedirect, getRequestAndRedirectExternalLink,
    tableFormFieldValueChange, tableFormFieldErrorChange,
    reloadScreenWithPostDataOnButtonClick, buttonFormChangeValue,
    treeExpandElement, treeCollapseElement, treeExpandAllElements, treeCollapseAllElements,
    treeOnElementClickMutateScreenWithPostData, treeSelectElement, infoPanelHide,
    openModalForm, onButtonClickMutateComponentWithPostData, onButtonClickMutateComponentNoPostData,
    onFieldChangeMutateComponentWithPostData, downloadFile,
} from '../actions/newScreen/newScreenActions';
import {
    onGridRecordsPerPageChangeMutateComponentWithPostData, onGridActivePageChangeMutateComponentWithPostData,
    onGridSortChangeMutateComponentWithPostData, onApplyAllFiltersMutateComponentWithPostData,
    onDeleteAllFiltersMutateComponentWithPostData, onDeleteAppliedFilterRowMutateComponentWithPostData,
    createNewFilter, onDeleteNotAppliedFilterRow, onFilterValueChange, onFilterComparatorChange,
    onFilterColumnChange, clearAllNotAppliedEmptyFilters, selectAll, selectSingle, onDeleteRecordMutateComponentWithPostData,
} from '../actions/newScreen/dataGridActions';
import Flow from './flow/Flow';
import Tree from './tree/Tree';
import TreeWithSidePanel from './tree/TreeWithSidePanel';
import InfoPanel from './infoPanel/InfoPanel';
import ModalForm from './form/form/ModalForm';
import DataGrid from './dataGrid/DataGrid';
import { MESSAGE_ERROR } from '../helpers/const';

const NewScreen = (props) => {
    const {
        match, components, showSpinner, fetchScreenData, formFieldValueChange, actionDownload, submitForm,
        formFieldErrorChange, clear, onFieldChangeMutateScreenWithPostData, onFieldChangeMutateScreenNoPostData,
        onButtonClickMutateScreenWithPostData, onButtonClickMutateScreenNoPostData, postDataAndRedirect, getRequestAndRedirectExternalLink,
        tableFormFieldValueChange, tableFormFieldErrorChange, reloadScreenWithPostDataOnButtonClick,
        treeExpandElement, treeCollapseElement, treeExpandAllElements, treeCollapseAllElements,
        treeOnElementClickMutateScreenWithPostData, treeSelectElement, buttonFormChangeValue,
        infoPanelHide, openModalForm, onButtonClickMutateComponentWithPostData, onButtonClickMutateComponentNoPostData,
        onFieldChangeMutateComponentWithPostData, logout, addMessage, downloadFile,

        onGridRecordsPerPageChangeMutateComponentWithPostData, onGridActivePageChangeMutateComponentWithPostData,
        onGridSortChangeMutateComponentWithPostData, onApplyAllFiltersMutateComponentWithPostData,
        onDeleteAllFiltersMutateComponentWithPostData, onDeleteAppliedFilterRowMutateComponentWithPostData,
        createNewFilter, onDeleteNotAppliedFilterRow, onFilterValueChange, onFilterComparatorChange,
        onFilterColumnChange, clearAllNotAppliedEmptyFilters, selectAll, selectSingle, onDeleteRecordMutateComponentWithPostData,
    } = props;

    useEffect(() => {
        checkAuth();

        let routeInfo = getRouteObj(match.path);
        let backEndUrl = routeInfo.API_FETCH_URL;

        const routeParams = props.match.params;
        if (routeParams) {
            _.forEach(routeParams, (paramValue, paramName) => {
                backEndUrl = backEndUrl.replace(':' + paramName, paramValue);
            });
        } else {
            backEndUrl = backEndUrl;
        }

        const currentRoute = getRouteObj(props.match.path); 
        const currentRouteName = currentRoute.NAME;
        const preservedFormData = props.preserverdGridParams;

        let postFormData = null;
        if (preservedFormData && preservedFormData[currentRouteName]) {
            postFormData = preservedFormData[currentRouteName];
        }

        const fetchData = async (backEndUrl, postFormData) => fetchScreenData(backEndUrl, postFormData);
        fetchData(backEndUrl, postFormData);

        return () => clear();
    }, [])

    const onActionSubmitForm = (params) => {
        const allFieldsAreValid = validateNewScreenComponentsFields({
            components: components,
            onFormUpdateErrorAction: (params) => formFieldErrorChange(params),
            onTableFormUpdateErrorAction: (params) => tableFormFieldErrorChange(params),
        });

        if (allFieldsAreValid) {
            submitForm(params);
        }
    }

    const reloadScreenWithPostData = (params) => {
        const { makeBackEndCallOnlyIfDataIsValid } = params;

        if (makeBackEndCallOnlyIfDataIsValid) {
            const allFieldsAreValid = validateNewScreenComponentsFields({
                components: components,
                onFormUpdateErrorAction: (params) => formFieldErrorChange(params),
                onTableFormUpdateErrorAction: (params) => tableFormFieldErrorChange(params),
            });

            if (allFieldsAreValid) {
                reloadScreenWithPostDataOnButtonClick(params);
            }
        } else {
            reloadScreenWithPostDataOnButtonClick(params);
        }
    }

    const onActionPostDataAndRedirect = (params) => {
        const { makeBackEndCallOnlyIfDataIsValid } = params;

        if (makeBackEndCallOnlyIfDataIsValid) {
            const allFieldsAreValid = validateNewScreenComponentsFields({
                components: components,
                onFormUpdateErrorAction: (params) => formFieldErrorChange(params),
                onTableFormUpdateErrorAction: (params) => tableFormFieldErrorChange(params),
            });

            if (allFieldsAreValid) {
                postDataAndRedirect(params);
            } else {
                addMessage(MESSAGE_ERROR, { content: getValue(props.translations, STRINGS.ERRORS_IN_SCREEN), visible: true })
            }
        } else {
            postDataAndRedirect(params);
        }
    }

    if (showSpinner) {
        return (
            <Segment basic style={{ marginTop: '20%' }}>
                <Dimmer active inverted>
                    <Loader size='big' inverted>Loading</Loader>
                </Dimmer>
            </Segment>
        );
    }

    return (
        <Segment basic>
            {
                _.map(components, (screenComponentProps, componentName) => {
                    const componentType = screenComponentProps[ScreenComponentProp.ScreenComponentType];
                    const componentPosition = screenComponentProps[ScreenComponentProp.Position];

                    switch (componentType) {
                        case ScreenComponentType.DataGrid:
                            const currentRoute = getRouteObj(props.match.path); 
                            const currentRouteName = currentRoute.NAME; 
                            const preserveFilters = true; 

                            return (
                                <DataGrid
                                    key={componentPosition}
                                    screenComponentProps={screenComponentProps}
                                    onSortClick={(params) => onGridSortChangeMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onRecordsPerPageChange={(params) => onGridRecordsPerPageChangeMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onPageChange={(params) => onGridActivePageChangeMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onApplyFiltersClick={(params) => onApplyAllFiltersMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onDeleteAllFiltersClick={(params) => onDeleteAllFiltersMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onDeleteAppliedFilterRowClick={(params) => onDeleteAppliedFilterRowMutateComponentWithPostData({ ...params, componentName, currentRouteName, preserveFilters })}
                                    onDeleteNotAppliedFilterRowClick={(params) => onDeleteNotAppliedFilterRow({ ...params, componentName })}
                                    onCreateRowClick={(params) => createNewFilter({ ...params, componentName })}

                                    getRequestAndRedirectExternalLink={(params) => getRequestAndRedirectExternalLink({ ...params, componentName, componentType })}

                                    onFilterColumnFieldChange={(params) => onFilterColumnChange({ ...params, componentName })}
                                    onFilterComparatorFieldChange={(params) => onFilterComparatorChange({ ...params, componentName })}
                                    onFilterValueFieldChange={(params) => onFilterValueChange({ ...params, componentName })}
                                    clearAllNotAppliedEmptyFilters={() => clearAllNotAppliedEmptyFilters({ componentName })}
                                    selectAll={(params) => selectAll({ ...params, componentName })}
                                    selectSingle={(params) => selectSingle({ ...params, componentName })}
                                    mutateComponentWitPostData={onDeleteRecordMutateComponentWithPostData}
                                    openModalForm={openModalForm}
                                    onButtonClickMutateComponentWithPostData={(params) => onButtonClickMutateComponentWithPostData({ ...params, componentName, componentType })}
                                    reloadScreenWithPostDataOnButtonClick={(params) => reloadScreenWithPostData({ ...params, componentName, componentType, showMessages: true })}
                                    translations={props.translations}
                                    onActionDownload={downloadFile}
                                />
                            );
                        case ScreenComponentType.InfoPanel:
                            return (
                                <InfoPanel
                                    key={componentPosition}
                                    screenComponentProps={screenComponentProps}
                                    actionHide={() => infoPanelHide({ componentName })}
                                />
                            );
                        case ScreenComponentType.Tree:
                            return (
                                <Tree
                                    key={componentPosition}
                                    screenComponentProps={screenComponentProps}
                                    uniqueKey={componentPosition}
                                    expandAll={(params) => treeExpandAllElements({ ...params, componentName })}
                                    collapseAll={(params) => treeCollapseAllElements({ ...params, componentName })}
                                    expand={(params) => treeExpandElement({ ...params, componentName })}
                                    collapse={(params) => treeCollapseElement({ ...params, componentName })}
                                    onElementSelect={(params) => { treeSelectElement({ ...params, componentName }) }}
                                    mutateWithPostData={treeOnElementClickMutateScreenWithPostData}
                                />
                            );
                        case ScreenComponentType.TreeWithSidePanel:
                            return (
                                <TreeWithSidePanel
                                    screenComponentProps={screenComponentProps}
                                    key={componentPosition}
                                    uniqueKey={componentPosition}
                                    expandAll={(params) => treeExpandAllElements({ ...params, componentName })}
                                    collapseAll={(params) => treeCollapseAllElements({ ...params, componentName })}
                                    expand={(params) => treeExpandElement({ ...params, componentName })}
                                    collapse={(params) => treeCollapseElement({ ...params, componentName })}
                                    changeValue={(params) => buttonFormChangeValue({ ...params, componentName })}
                                    onElementSelect={(params) => { treeSelectElement({ ...params, componentName }) }}
                                    mutateWithPostData={treeOnElementClickMutateScreenWithPostData}
                                />
                            );
                        case ScreenComponentType.Flow:
                            return (
                                <Flow
                                    key={componentPosition}
                                    uniqueKey={componentPosition}
                                    componentName={componentName}
                                    screenComponentProps={screenComponentProps}
                                    reloadScreenWithPostDataOnButtonClick={(params) => reloadScreenWithPostData({ ...params, componentName, componentType })}
                                />
                            );
                        case ScreenComponentType.Title:
                            return (
                                <ScreenTitle
                                    key={componentPosition}
                                    uniqueKey={componentPosition}
                                    componentName={componentName}
                                    screenComponentProps={screenComponentProps}
                                />
                            );
                        case ScreenComponentType.Form:
                            return (
                                <React.Fragment key={componentPosition}>
                                    <Form
                                        key={componentPosition}
                                        uniqueKey={componentPosition}
                                        className={'MyForm'}
                                        screenComponentProps={screenComponentProps}
                                        onFormValueChange={(params) => formFieldValueChange({ ...params, componentName, componentType })}
                                        updateFieldErrorAction={(params) => formFieldErrorChange({ ...params, componentName, componentType })}
                                        onActionMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                                        onActionMutateScreenNoPostData={onFieldChangeMutateScreenNoPostData}
                                        onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                                        actionDownload={actionDownload}
                                    />
                                </React.Fragment>
                            );
                        case ScreenComponentType.ModalForm:
                            return (
                                <React.Fragment key={componentPosition}>
                                    <ModalForm
                                        key={componentPosition}
                                        uniqueKey={componentPosition}
                                        className={'MyForm'}
                                        screenComponentProps={screenComponentProps}
                                        onFormValueChange={(params) => formFieldValueChange({ ...params, componentName, componentType })}
                                        updateFieldErrorAction={(params) => formFieldErrorChange({ ...params, componentName, componentType })}
                                        onActionMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                                        onActionMutateScreenNoPostData={onFieldChangeMutateScreenNoPostData}

                                        onButtonClickMutateScreenWithPostData={(params) => onButtonClickMutateScreenWithPostData({ ...params, componentName, componentType })}
                                        onButtonClickMutateScreenNoPostData={(params) => onButtonClickMutateScreenNoPostData({ ...params, componentName, componentType })}
                                        reloadScreenWithPostDataOnButtonClick={(params) => reloadScreenWithPostData({ ...params, componentName, componentType, showMessages: true })}
                                        onActionDownload={actionDownload}
                                        onActionLogout={logout}
                                        openModalForm={openModalForm}
                                        onActionPostDataAndRedirect={(params) => onActionPostDataAndRedirect({ ...params, componentName, componentType })}

                                        onButtonClickMutateComponentWithPostData={(params) => onButtonClickMutateComponentWithPostData({ ...params, componentName, componentType })}
                                        onButtonClickMutateComponentNoPostData={(params) => onButtonClickMutateComponentNoPostData({ ...params, componentName, componentType })}
                                        onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                                    />
                                </React.Fragment>
                            );
                        case ScreenComponentType.Buttons:
                            return (
                                <React.Fragment key={componentPosition}>
                                    <ButtonRow
                                        key={componentPosition}
                                        uniqueKey={componentPosition}
                                        screenComponentProps={screenComponentProps}
                                        onActionSubmitForm={(params) => onActionSubmitForm({ ...params, componentName, componentType })}
                                        onActionMutateScreenWithPostData={(params) => onButtonClickMutateScreenWithPostData({ ...params, componentName, componentType })}
                                        onActionMutateScreenNoPostData={(params) => onButtonClickMutateScreenNoPostData({ ...params, componentName, componentType })}
                                        reloadScreenWithPostDataOnButtonClick={(params) => reloadScreenWithPostData({ ...params, componentName, componentType })}
                                        onActionDownload={actionDownload}
                                        openModalForm={openModalForm}
                                        onActionPostDataAndRedirect={(params) => onActionPostDataAndRedirect({ ...params, componentName, componentType })}

                                        getRequestAndRedirectExternalLink={(params) => getRequestAndRedirectExternalLink({ ...params, componentName, componentType })}

                                        onButtonClickMutateComponentWithPostData={(params) => onButtonClickMutateComponentWithPostData({ ...params, componentName, componentType })}
                                        onButtonClickMutateComponentNoPostData={(params) => onButtonClickMutateComponentNoPostData({ ...params, componentName, componentType })}
                                    />

                                    <Divider className='MyForm top' />
                                </React.Fragment>
                            );
                        case ScreenComponentType.Table:
                            return (
                                <Table
                                    key={componentPosition}
                                    uniqueKey={componentPosition}
                                    screenComponentProps={screenComponentProps}
                                />
                            );
                        case ScreenComponentType.TableForm:
                            return (
                                <TableForm
                                    key={componentPosition}
                                    uniqueKey={componentPosition}
                                    screenComponentProps={screenComponentProps}
                                    onButtonClickMutateScreenWithPostData={(params) => onButtonClickMutateScreenWithPostData({ ...params, componentName, componentType })}
                                    onFieldChangeMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                                    onInputFieldChange={(props) => tableFormFieldValueChange({ ...props, componentName, componentType })}
                                    updateInputFieldErrorAction={(params) => tableFormFieldErrorChange({ ...params, componentName, componentType })}
                                    onActionDownload={actionDownload}
                                    onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                                    openModalForm={openModalForm}
                                />
                            );
                        default:
                            return <h1 key={componentPosition}>Unregistered Component Type - {componentType}</h1>
                    }
                })
            }
        </Segment >
    );
}

const mapStateToProps = (state) => {
    const components = state.screenData[ScreenProp.Components];
    const showSpinner = state.screenData[ScreenProp.ShowSpinner];
    const translations = state.auth.translations;
    const preserverdGridParams = state.gridFilterParamsReducer;

    return {
        components: components,
        showSpinner: showSpinner,
        translations: translations,
        preserverdGridParams: preserverdGridParams,
    };
};

const actions = {
    fetchScreenData, formFieldValueChange, formFieldErrorChange, actionDownload,
    onFieldChangeMutateScreenWithPostData, onFieldChangeMutateScreenNoPostData,
    onButtonClickMutateScreenWithPostData, onButtonClickMutateScreenNoPostData,
    postDataAndRedirect, getRequestAndRedirectExternalLink,
    submitForm, clear, tableFormFieldValueChange, tableFormFieldErrorChange,
    reloadScreenWithPostDataOnButtonClick, treeExpandElement, treeCollapseElement,
    treeExpandAllElements, treeCollapseAllElements, treeOnElementClickMutateScreenWithPostData,
    treeSelectElement, buttonFormChangeValue, infoPanelHide, openModalForm,
    onButtonClickMutateComponentWithPostData, onButtonClickMutateComponentNoPostData,
    onFieldChangeMutateComponentWithPostData, logout, addMessage, downloadFile,

    onGridRecordsPerPageChangeMutateComponentWithPostData, onGridActivePageChangeMutateComponentWithPostData,
    onGridSortChangeMutateComponentWithPostData, onApplyAllFiltersMutateComponentWithPostData,
    onDeleteAllFiltersMutateComponentWithPostData, onDeleteAppliedFilterRowMutateComponentWithPostData,
    createNewFilter, onDeleteNotAppliedFilterRow, onFilterValueChange, onFilterComparatorChange,
    onFilterColumnChange, clearAllNotAppliedEmptyFilters, selectAll, selectSingle, onDeleteRecordMutateComponentWithPostData,
};

export default connect(mapStateToProps, actions)(NewScreen);
